import { IGlobalContext } from "./types";

export const globalContextInitial: IGlobalContext = {
  loading: {
    id: undefined,
    state: false,
  },
  companyData: undefined,
  currentCompany: undefined,
  allEmployees: {
    data: undefined,
    total: undefined,
  },
  updateRoutes: undefined,
};
