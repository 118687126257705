export enum EGlobalActions {
  SET_COMPANY_DATA = "SET_COMPANY_DATA",
  GET_COMPANY_DATA = "GET_COMPANY_DATA",
  SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY",
  SET_ALL_EMPLOYEES = "SET_ALL_EMPLOYEES",
  SET_LOADING = "SET_LOADING",
  SET_UPDATEROUTES = "SET_UPDATEROUTES",
}

export interface IGlobalActions {
  type: EGlobalActions;
  payload?: any;
}
