import {
  Pagination,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const GridPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: ${(props) => props.theme.spacings.xs} 0;

  gap: 10px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled(Typography).attrs({
  variant: "body3",
})`
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral.dark.dark5};
  white-space: nowrap;
`;

export const SelectItemsPerPage = styled(SelectField)`
  .data-grid-custom-select {
    border-radius: 50px;
    .Mui-selected {
      line-height: 1;
    }
  }
`;

export const Pages = styled(Pagination).attrs({
  color: "secondary",
  size: "large",
})`
  .MuiButtonBase-root {
    line-height: 0;
  }
`;
