import { useQuery } from "@apollo/client";
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { COUNT_CARDS_INFO } from "../../../api/queries/count-cards-info";
import dispatchToast from "../../../utils/dispatchToast";
import { BigNumber, ReportBox, ReportBoxDescription } from "./components";
import { ReportBoxContainer, ReportContainer } from "./styled";

interface CountCardsInfoResponse {
  countCardsInfo: {
    activatedCards: number;
    cardsInStock: number;
    cardsInFlight: number;
  };
}

export const Report = () => {
  const {
    data: bigNumbersData,
    loading: loadingCountCards,
    refetch: countCardsInfo,
  } = useQuery<CountCardsInfoResponse>(COUNT_CARDS_INFO, {
    fetchPolicy: "network-only",
    onError: () => {
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao buscar quantidade de cartões ativos, em estoque e em trânsito.",
      });
    },
  });

  return (
    <ReportContainer>
      <Typography variant="headline7" style={{ color: "#53464F" }}>
        Relatórios
      </Typography>
      <ReportBoxContainer>
        {loadingCountCards ? (
          <>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={120}
              style={{ marginRight: "16px", borderRadius: "50px" }}
            />
          </>
        ) : (
          <>
            <ReportBox
              title={
                <BigNumber
                  number={bigNumbersData?.countCardsInfo?.activatedCards}
                />
              }
              description={
                <ReportBoxDescription description="Cartões ativos" />
              }
            />
            <ReportBox
              title={
                <BigNumber
                  number={bigNumbersData?.countCardsInfo?.cardsInStock}
                />
              }
              description={
                <ReportBoxDescription description="Cartões no estoque" />
              }
            />
            <ReportBox
              title={
                <BigNumber
                  number={bigNumbersData?.countCardsInfo?.cardsInFlight}
                />
              }
              description={
                <ReportBoxDescription description="Cartões em trânsito" />
              }
            />
          </>
        )}
      </ReportBoxContainer>
    </ReportContainer>
  );
};
