interface ResultFile {
  name: string;
  uri: string | ArrayBuffer;
}
async function readFile(file: File): Promise<ResultFile> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.onload = (event) => {
      const bstr = event.target.result;
      resolve({
        name: file.name,
        uri: bstr,
      });
    };
    reader.readAsBinaryString(file);
  });
}

export { readFile };
