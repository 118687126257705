import styled from "styled-components";

export const HeaderContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const BannerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #8ac1fe;
  background-color: #e7f2fe;
  color: #00244c;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: -20px;
`;

export const BannerTitle = styled.p`
  font-size: 1.2em;
  margin-bottom: 5px;
  font-weight: 600;
`;

export const BannerLink = styled.a`
  text-decoration: underline;
  color: #015fcb;
  line-height: 3;
`;

export const InfoIcon = styled.div`
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d4e8ff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;

  &::before {
    content: "i";
    color: #015fcb;
    font-size: 25px;
    font-weight: bold;
  }
`;
