import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Cards } from "../pages";
import { RoutesGuard } from "./routesGuard";
import { CardsPageProvider } from "../pages/Cards/Context/CardsContext";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard />}>
          <Route
            path="/cards"
            element={
              <CardsPageProvider>
                {" "}
                <Cards />{" "}
              </CardsPageProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
