import {
  Button,
  IconButton,
  Modal,
  TextField,
  Toast,
  Typography,
  useToasts,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { RESOLVE_DELIVERY_INCONSISTENCIES } from "../../../../api/mutations/resolve-delivery-inconsistencies";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { resolveDeliveryInconsistenciesSchema } from "./CpfInconsistencyForm/cpfInconsistencySchema";
import { cpfMask, onlyNumbers } from "../../../../utils/helpers";

interface CpfInconsistencyModalProps {
  open: boolean;
  onClose: () => void;
  deliveryInconsistencies?: {
    cpf?: boolean;
  };
  externalCardDeliveryId: string;
}

const defaultFormValues = {
  cpf: "",
};

export const CpfInconsistencyModal = ({
  open,
  onClose,
  deliveryInconsistencies,
  externalCardDeliveryId,
}: CpfInconsistencyModalProps) => {
  const { showToast } = useToasts();

  const methods = useForm({
    resolver: zodResolver(resolveDeliveryInconsistenciesSchema),
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: defaultFormValues,
  });

  const [resolveDeliveryInconsistencies, { loading }] = useMutation(
    RESOLVE_DELIVERY_INCONSISTENCIES,
    {
      onCompleted: () => {
        showToast("", {
          content: (key) => {
            return (
              <div>
                <Toast
                  id={key}
                  type={"success"}
                  title={"Sucesso"}
                  description={"CPF atualizado com sucesso."}
                />
              </div>
            );
          },
        });
        methods.reset(defaultFormValues);
        onClose();
      },
      onError: (error) =>
        showToast("", {
          content: (key) => {
            console.log(error.message);
            return (
              <div>
                <Toast
                  id={key}
                  type={"error"}
                  title={"Erro"}
                  description={
                    error.message.includes("employee not found")
                      ? "Não conseguimos encontrar um colaborador com esse CPF. Cadastre-o e tente novamente."
                      : "Algo aconteceu na atualização do CPF. Por favor, tente novamente mais tarde."
                  }
                />
              </div>
            );
          },
        }),
    },
  );

  const onSubmit = () => {
    resolveDeliveryInconsistencies({
      variables: {
        resolveDeliveryInconsistencies: {
          externalCardDeliveryId,
          cpf: onlyNumbers(methods.getValues("cpf")),
        },
      },
    });
  };

  const renderInputs = () => {
    const inputs: Array<JSX.Element> = [];
    if (deliveryInconsistencies?.cpf) {
      inputs.push(
        <TextField
          value={cpfMask(methods.watch("cpf"))}
          {...methods.register("cpf")}
          label="CPF*"
          error={!!methods.formState.errors.cpf}
          helperText={methods.formState.errors.cpf?.message as string}
        />,
      );
    }

    return inputs;
  };

  const disableSubmit = (): boolean => {
    return (
      loading ||
      methods.watch("cpf").length === 0 ||
      methods.formState.errors.cpf !== undefined
    );
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={<Header onClose={onClose} />}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <BodyContainer>
            {renderInputs()}
            <BodyButtons>
              <Button
                style={{ width: "49%" }}
                variant="secondary"
                size="medium"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                style={{ width: "49%" }}
                variant="primary"
                size="medium"
                disabled={disableSubmit()}
              >
                Atualizar
              </Button>
            </BodyButtons>
          </BodyContainer>
        </form>
      </FormProvider>
    </StyledModal>
  );
};

const BodyContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 100%;
`;

const BodyButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
`;

const Header = ({ onClose }) => (
  <>
    <ButtonContainer>
      <CloseButton onClick={onClose} />
    </ButtonContainer>
    <HeaderContainer>
      <TitleContainer>
        <Title>Corrigir inconsistências da remessa</Title>
      </TitleContainer>
    </HeaderContainer>
  </>
);

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 80%;
  padding-left: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
`;

const TitleContainer = styled.div`
  width: 80%;
`;

const Title = styled(Typography).attrs({ variant: "headline6" })``;

const CloseButton = ({ onClick }) => {
  return (
    <IconButton icon="IconX" onClick={onClick} variant="line" size="small" />
  );
};

const StyledModal = styled(Modal.Root)`
  && {
    &.modal-custom-theme .modal-content-area {
      width: 100%;
      align-self: center;
    }
  }
`;
