import { Outlet, Navigate } from "react-router-dom";

import { getFromLS } from "@flash-hros/utility";
import { useEmployeePermissions } from "../utils/permissions";

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const accessToken = getFromLS("hrosAccessToken");
  const havePermission = useEmployeePermissions([
    "operations_view_card_orders",
    "operations_view_cards",
    "operations_create_card_order",
    "operations_create_batch_card_order",
  ]);

  if (!accessToken) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (!havePermission) {
    return <Navigate to={"/home"} />;
  }

  return children ? children : <Outlet />;
};
