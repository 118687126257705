import {
  BoxSpace,
  CardsInfoText,
  CardsInfoValue,
  InfoCardsBox,
  InputBox,
} from "./styled";
import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { onlyNumbers } from "../../../../../../../utils/helpers";
import { useFormContext } from "react-hook-form";
import { LimitData } from "../SelectCardOrderType";
import {
  AlertMessage,
  MessageContainer,
  MessageDisplay,
  MessageIconError,
  MessageIconSuccess,
} from "../../../../../../../components/AlertMessages/styled";
import { useEffect } from "react";

interface CardOrderQuantityProps {
  handleChange: (quantity: number) => void;
  cardOrderQuantity: number;
  limitData: LimitData;
}

const CardOrderQuantity = ({
  cardOrderQuantity,
  limitData,
  handleChange,
}: CardOrderQuantityProps) => {
  const {
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <InfoCardsBox>
        {limitData.allowedToOrder && (
          <>
            <CardsInfoText>Pedido de Cartões</CardsInfoText>
            <InputBox>
              <TextField
                {...register("cardOrderQuantity")}
                inputProps={{ min: 0, style: { padding: "10px" } }}
                fullWidth
                type="number"
                placeholder="Quantidade"
                size="small"
                value={cardOrderQuantity}
                onChange={(e) => {
                  clearErrors("cardOrderQuantity");
                  if (Number(onlyNumbers(e.target.value)) > 0) {
                    return handleChange(Number(onlyNumbers(e.target.value)));
                  } else {
                    return handleChange(undefined);
                  }
                }}
                error={!!errors.cardOrderQuantity?.message}
                helperText={
                  errors.cardOrderQuantity?.message
                    ? (errors.cardOrderQuantity?.message as string)
                    : ""
                }
              />
            </InputBox>
          </>
        )}
      </InfoCardsBox>
      {limitData.errorMessage ? (
        <MessageDisplay marginTop={"20px"} type={limitData.messageType}>
          <MessageContainer>
            <MessageIconError
              name={
                limitData.messageType === "error"
                  ? "IconCircleX"
                  : "IconExclamationMark"
              }
              size={24}
            />
            <AlertMessage fontSize={"14px"} type={limitData.messageType}>
              {limitData.errorMessage}
            </AlertMessage>
          </MessageContainer>
        </MessageDisplay>
      ) : (
        <BoxSpace />
      )}
    </>
  );
};

export { CardOrderQuantity };
