import {
  IconButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
import { CardDeliveriesTable } from "./CardDeliveriesTable";

interface CardOrderDetailsModalProps {
  open: boolean;
  onClose: () => void;
  externalCardOrderId: string;
}

export const CardOrderDetailsModal = ({
  open,
  onClose,
  externalCardOrderId,
}: CardOrderDetailsModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={<Header onClose={onClose} />}
    >
      <CardDeliveriesTable externalCardOrderId={externalCardOrderId} />
    </StyledModal>
  );
};

const Header = ({ onClose }) => (
  <>
    <ButtonContainer>
      <CloseButton onClick={onClose} />
    </ButtonContainer>
    <HeaderContainer>
      <TitleContainer>
        <Title>Detalhes do pedido (Remessas)</Title>
      </TitleContainer>
    </HeaderContainer>
  </>
);

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 90%;
  padding-left: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
`;

const TitleContainer = styled.div`
  width: 80%;
`;

const Title = styled(Typography).attrs({ variant: "headline6" })``;

const CloseButton = ({ onClick }) => {
  return (
    <IconButton icon="IconX" onClick={onClick} variant="line" size="small" />
  );
};

export const StyledModal = styled(Modal.Root)`
  && {
    &.modal-custom-theme .modal-content-area {
      width: 100%;
      align-self: center;
    }
  }
`;
