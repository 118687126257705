import { Checkbox, Icons, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Box, Grid } from "@mui/material";
import { FieldErrors, useFormContext } from "react-hook-form";
import { ICreateCardOrderForm } from "../../../CreateCardOrderForm/createCardOrderForm.interfaces";
import { CardOrderInfoTitle } from "../../SelectCardOrderType/styled";
import {
  cpfMask,
  inputPhoneMask,
  onlyNumbers,
} from "../../../../../../../utils/helpers";

type SingleCardOrderRecipientFields =
  | "recipientName"
  | "recipientPhone"
  | "recipientEmail"
  | "cpf";

export const SINGLE_CARD_ORDER_RECIPIENT_FIELDS: SingleCardOrderRecipientFields[] =
  ["recipientName", "recipientPhone", "recipientEmail", "cpf"];

export const checkSingleCardOrderRecipientFields = (
  errors: FieldErrors<ICreateCardOrderForm>,
) => {
  const result = Object.entries(errors).filter(
    ([error]) =>
      SINGLE_CARD_ORDER_RECIPIENT_FIELDS.indexOf(
        error as SingleCardOrderRecipientFields,
      ) >= 0,
  );

  return result.reduce((prev, [key, value]) => (prev[key] = value), {});
};

export const SingleCardOrderFormRecipient = () => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  return (
    <>
      <CardOrderInfoTitle>Dados do Destinatário</CardOrderInfoTitle>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            InputLabelProps={{ shrink: !!watch("recipientName") }}
            {...register("recipientName")}
            fullWidth
            label="Nome do recebedor*"
            error={!!errors.recipientName}
            helperText={errors.recipientName?.message as string}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            {...register("recipientEmail")}
            fullWidth
            label="E-mail*"
            error={!!errors.recipientEmail}
            helperText={errors.recipientEmail?.message as string}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            {...register("recipientPhone")}
            fullWidth
            label="Telefone*"
            placeholder="Exemplo: DD 99999-9999"
            error={!!errors.recipientPhone}
            helperText={errors.recipientPhone?.message as string}
            onChange={(e) => {
              const inputValue = e.target.value;
              const formattedValue = inputPhoneMask(inputValue);
              setValue("recipientPhone", formattedValue);
            }}
          />
        </Grid>
        {watch("cardOrderQuantity") == 1 ? (
          <>
            <Grid item sm={12}>
              <Checkbox
                {...register("isSearchEmployeeByCpfEnable")}
                checked={watch("isSearchEmployeeByCpfEnable")}
                onChange={() => {
                  setValue(
                    "isSearchEmployeeByCpfEnable",
                    !watch("isSearchEmployeeByCpfEnable"),
                  );
                  setValue("cpf", "");
                }}
              />
              Vincular remessa a um CPF
            </Grid>
            <Grid
              item
              sm={12}
              sx={{ display: "flex", alignItems: "center" }}
              container
              spacing={2}
            >
              <Icons
                style={{ margin: 10, marginLeft: "23px" }}
                color="#C96C01"
                name="IconAlertCircle"
                fill="transparent"
                size={30}
              />
              <Box>
                Vincular CPF a remessa permite visualização e acompanhamento no
                app
              </Box>
            </Grid>
            {watch("isSearchEmployeeByCpfEnable") ? (
              <Grid item sm={12}>
                <TextField
                  {...register("cpf")}
                  fullWidth
                  onChange={(e) => {
                    const cpfInput = e.target.value;
                    let formattedValue = cpfInput;
                    if (onlyNumbers(cpfInput).length === 11) {
                      formattedValue = cpfMask(cpfInput);
                    } else {
                      formattedValue = cpfInput.replace(/[^\d]/g, "");
                    }
                    setValue("cpf", formattedValue);
                  }}
                  placeholder="Exemplo: 999.999.999-99"
                  label="CPF"
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message as string}
                />
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};
