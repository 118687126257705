import { gql } from "@apollo/client";

export const CREATE_BATCH_CARD_ORDER = gql`
  mutation CreateBatchCardOrder(
    $createBatchCardOrder: CreateBatchCardOrderInput!
  ) {
    createBatchCardOrder(createBatchCardOrder: $createBatchCardOrder) {
      externalCardOrderId
      status
      cardAmount
      createdAt
      deadline
    }
  }
`;
