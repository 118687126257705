import { CardsInfoText, CardsInfoValue, InfoCardsBox } from "./styled";

interface CardsInStockProps {
  cardsInStock: number;
}

const CardsInStock = ({ cardsInStock }: CardsInStockProps) => {
  return (
    <InfoCardsBox>
      <CardsInfoText>Cartões em Estoque / em Trânsito</CardsInfoText>
      <CardsInfoValue>{cardsInStock}</CardsInfoValue>
    </InfoCardsBox>
  );
};

export { CardsInStock };
