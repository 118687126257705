import { EGlobalActions, IGlobalActions } from "./actions";
import { IGlobalContext } from "./types";

export const reducer = (state: IGlobalContext, action: IGlobalActions) => {
  switch (action.type) {
    case EGlobalActions.SET_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
      };

    case EGlobalActions.SET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };

    case EGlobalActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EGlobalActions.SET_UPDATEROUTES:
      return {
        ...state,
        updateRoutes: action.payload,
      };

    default:
      throw new Error("Error in global context reducer");
  }
};
