import { gql } from "@apollo/client";

export const COUNT_CARDS_INFO = gql`
  query CountCardsInfo {
    countCardsInfo {
      activatedCards
      cardsInStock
      cardsInFlight
    }
  }
`;
