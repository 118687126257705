import styled from "styled-components";

export const SelectCardOrderTypeContainer = styled.div`
  margin: 10px 0px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const SelectCardOrderTypeSection = styled.div`
  margin: 10px 0px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

interface CardOrderInfoTitleProps {
  margin?: string;
}

export const CardOrderInfoTitle = styled.h2<CardOrderInfoTitleProps>`
  ${({ margin }) =>
    margin
      ? `
      margin: ${margin};
  `
      : `
    margin: 20px 0px 20px 0px;
  `}

  color: #53464f;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const CardOrderInfoText = styled.div`
  margin: 10px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #83727d;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;
