import { ApolloProvider } from "@apollo/client";
import { ThemeProvider, ToastProvider } from "@flash-tecnologia/hros-web-ui-v2";

import { makeApolloClient } from "./utils/apollo";

import AppRouter from "./routes";
import "../src/i18n";
import { GlobalContextProvider } from "./pages/globalContext/index";
import { CustomApolloProvider } from "./contexts/CustomApolloProvider";

export default function Root() {
  return (
    <CustomApolloProvider>
      <ThemeProvider>
        <ToastProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          maxSnack={3}
        >
          <GlobalContextProvider>
            <AppRouter />
          </GlobalContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </CustomApolloProvider>
  );
}
