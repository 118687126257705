import { gql } from "@apollo/client";

export const RESOLVE_DELIVERY_INCONSISTENCIES = gql`
  mutation ResolveDeliveryInconsistencies(
    $resolveDeliveryInconsistencies: ResolveDeliveryInconsistenciesInput!
  ) {
    resolveDeliveryInconsistencies(
      resolveDeliveryInconsistencies: $resolveDeliveryInconsistencies
    ) {
      message
    }
  }
`;

export interface ResolveDeliveryInconsistenciesInput {
  externalCardDeliveryId: string;
  cpf?: string;
}
