import { DropzoneFile } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types";
import { createContext, ReactNode, useContext, useState } from "react";

interface CardOrderContextData {
  cardOrderQuantity?: number;
  cardOrderType?: string;
  canContinueWithOrder?: boolean;
  submittedSheets?: DropzoneFile[];
  handleSetSubmittedSheets?: (sheet: DropzoneFile[]) => void;
  handleChangeCardOrderQuantity?: (quantity?: number) => void;
  handleChangeCardOrderType?: (cardOrderType: string) => void;
  handleCanContinueWithOrder?: (canContinue: boolean) => void;
}

interface CardOrderProviderProps {
  children: ReactNode;
}

export const CardOrderContext = createContext<CardOrderContextData>({});

function CardOrderProvider({ children }: CardOrderProviderProps) {
  const [cardOrderQuantity, setCardOrderQuantity] = useState<
    number | undefined
  >();
  const [canContinueWithOrder, setCanContinueWithOrder] =
    useState<boolean>(true);
  const [cardOrderType, setCardOrderType] = useState<string | undefined>();
  const [submittedSheets, setSubmittedSheets] = useState<
    DropzoneFile[] | undefined
  >();

  const handleSetSubmittedSheets = (sheets: DropzoneFile[]) =>
    setSubmittedSheets(sheets);

  const handleChangeCardOrderQuantity = (quantity?: number) =>
    setCardOrderQuantity(quantity);

  const handleChangeCardOrderType = (cardOrderType: string) =>
    setCardOrderType(cardOrderType);

  const handleCanContinueWithOrder = (canContinue: boolean) =>
    setCanContinueWithOrder(canContinue);

  return (
    <CardOrderContext.Provider
      value={{
        cardOrderQuantity,
        handleChangeCardOrderQuantity,
        cardOrderType,
        handleChangeCardOrderType,
        submittedSheets,
        handleSetSubmittedSheets,
        canContinueWithOrder,
        handleCanContinueWithOrder,
      }}
    >
      {children}
    </CardOrderContext.Provider>
  );
}

function useCardOrderContext() {
  const context = useContext(CardOrderContext);

  return context;
}

export { CardOrderProvider, useCardOrderContext };
