import {
  getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { Authentication } from "@flash-hros/auth-helper";
import { PropsWithChildren } from "react";

const { CognitoSignOut } = Authentication;
const { CARD_ORDER_PROXY_URL } = process.env;

const httpLink = new HttpLink({
  uri: CARD_ORDER_PROXY_URL,
});

const authLink = onError(({ graphQLErrors, operation }) => {});

const cache = new InMemoryCache({
  addTypename: false,
});

export const CustomApolloProvider = ({ children }: PropsWithChildren) => {
  const { selectedCompany } = useSelectedCompany();
  const contextLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken();

    const context = {
      headers: {
        ...headers,
        ...(selectedCompany && {
          companyid: selectedCompany.id,
        }),
        ...(accessToken && {
          Authorization: `Bearer ${accessToken}`,
        }),
      },
    };
    return context;
  });

  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, authLink, httpLink]),
    cache,
    resolvers: {},
  });

  client.onClearStore(async () => {
    await CognitoSignOut({});
    return;
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
