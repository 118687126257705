import { gql } from "@apollo/client";

export const GET_CARD_ORDER_METADATA = gql`
  query GetCardOrderMetadata {
    getCardOrderMetadata {
      isExpenseManagement
      isFirstOrder
      hasBilletNonCancelled
      additionalCardsLimit
      metadata {
        employeeWithoutCards
        cardsInStock
        cardsInFlight
        allowedCards
      }
    }
  }
`;

export type GetCardOrderMetadataResponse = {
  getCardOrderMetadata: {
    isExpenseManagement: boolean;
    isFirstOrder: boolean;
    hasBilletNonCancelled: boolean;
    additionalCardsLimit: number;
    metadata: {
      employeeWithoutCards: number;
      cardsInStock: number;
      cardsInFlight: number;
      allowedCards: number;
    };
  };
};
