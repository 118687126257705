import {
  SearchField,
  TagCheckboxFilter,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ModalTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  table th {
    position: sticky;
    top: 0;
  }

  .data-grid-table-container
    .data-grid-table-header-container
    .data-grid-table-header-container
    .data-grid-table-header-subcontainer {
    padding: 12px 24px;
  }
`;

export const StyledTagCheckBoxFilter = styled(TagCheckboxFilter)`
  margin-bottom: 10px;
`;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: 10px;
`;
