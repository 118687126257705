import {
  MenuOption,
  Menu,
  IconButton,
  MenuOptionProps,
} from "@flash-tecnologia/hros-web-ui-v2";

export function MenuActions({
  menuOptions,
}: {
  menuOptions: MenuOptionProps[];
}) {
  return (
    <Menu type="default" locked options={menuOptions}>
      <IconButton size="medium" variant="line" icon="IconDotsVertical" />
    </Menu>
  );
}
