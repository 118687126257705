export const stateMap = {
  ac: {
    label: "Acre",
    name: "ACRE",
    value: "AC",
  },
  al: {
    label: "Alagoas",
    name: "ALAGOAS",
    value: "AL",
  },
  ap: {
    label: "Amapá",
    name: "AMAPÁ",
    value: "AP",
  },
  am: {
    label: "Amazonas",
    name: "AMAZONAS",
    value: "AM",
  },
  ba: {
    label: "Bahia",
    name: "BAHIA",
    value: "BA",
  },
  ce: {
    label: "Ceará",
    name: "CEARÁ",
    value: "CE",
  },
  df: {
    label: "Distrito Federal",
    name: "DISTRITO FEDERAL",
    value: "DF",
  },
  es: {
    label: "Espírito Santo",
    name: "ESPÍRITO SANTO",
    value: "ES",
  },
  go: {
    label: "Goiás",
    name: "GOIÁS",
    value: "GO",
  },
  ma: {
    label: "Maranhão",
    name: "MARANHÃO",
    value: "MA",
  },
  mt: {
    label: "Mato Grosso",
    name: "MATO GROSSO",
    value: "MT",
  },
  ms: {
    label: "Mato Grosso do Sul",
    name: "MATO GROSSO DO SUL",
    value: "MS",
  },
  mg: {
    label: "Minas Gerais",
    name: "MINAS GERAIS",
    priority: 3,
    value: "MG",
  },
  pa: {
    label: "Pará",
    name: "PARÁ",
    value: "PA",
  },
  pb: {
    label: "Paraíba",
    name: "PARAÍBA",
    value: "PB",
  },
  pr: {
    label: "Paraná",
    name: "PARANÁ",
    value: "PR",
  },
  pe: {
    label: "Pernambuco",
    name: "PERNAMBUCO",
    value: "PE",
  },
  pi: {
    label: "Piauí",
    name: "PIAUÍ",
    value: "PI",
  },
  rj: {
    label: "Rio de Janeiro",
    name: "RIO DE JANEIRO",
    priority: 2,
    value: "RJ",
  },
  rn: {
    label: "Rio Grande do Norte",
    name: "RIO GRANDE DO NORTE",
    value: "RN",
  },
  rs: {
    label: "Rio Grande do Sul",
    name: "RIO GRANDE DO SUL",
    value: "RS",
  },
  ro: {
    label: "Rondônia",
    name: "RONDÔNIA",
    value: "RO",
  },
  rr: {
    label: "Roraima",
    name: "RORAIMA",
    value: "RR",
  },
  sc: {
    label: "Santa Catarina",
    name: "SANTA CATARINA",
    value: "SC",
  },
  sp: {
    label: "São Paulo",
    name: "SÃO PAULO",
    priority: 1,
    value: "SP",
  },
  se: {
    label: "Sergipe",
    name: "SERGIPE",
    value: "SE",
  },
  to: {
    label: "Tocantins",
    name: "TOCANTINS",
    value: "TO",
  },
};
