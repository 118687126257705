import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import styled from "styled-components";
import { CreateCardOrder } from "../Drawer/CreateCardOrder";
import { HeaderContainer } from "./styled";
import { useEmployeePermissions } from "../../../utils/permissions";
type Anchor = "top" | "left" | "bottom" | "right";

export const Header = () => {
  return (
    <HeaderContainer>
      <Typography variant="headline6" style={{ color: "#3B2E37" }}>
        Cartões
      </Typography>
      <Actions />
    </HeaderContainer>
  );
};

const Actions = () => {
  const [openModal, setOpenModal] = useState(false);

  const createCardOrderPermission = useEmployeePermissions([
    "operations_create_card_order",
  ]);
  const createBatchCardOrderPermission = useEmployeePermissions([
    "operations_create_batch_card_order",
  ]);

  const toggleDrawer = (
    open: boolean,
    event?: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenModal(open);
  };

  return (
    <ActionsContainer>
      <Button
        variant="primary"
        size="large"
        onClick={(e) => toggleDrawer(true, e)}
        onKeyDown={(e) => toggleDrawer(true, e)}
        style={{ marginRight: "10px" }}
        disabled={!createCardOrderPermission && !createBatchCardOrderPermission}
      >
        Pedir Cartões +
      </Button>
      <CreateCardOrder open={openModal} toggleDrawer={toggleDrawer} />
    </ActionsContainer>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  gap: 10px;
`;
