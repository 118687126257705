import {
  Box,
  Step,
  StepLabel,
  Stepper as MUIStepper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import React, { useState } from "react";

export interface Step {
  label: string;
  isOptional: boolean;
  component: JSX.Element;
  callback?: (args: any) => Promise<void>;
  callbackArgs?: any;
  backCallback?: (args: any) => Promise<void>;
  backCallbackArgs?: any;
  disableBack?: boolean;
  disableNext?: boolean;
  nextButtonLabel?: string;
  finishButtonLabel?: string;
}
interface Props {
  steps: Step[];
  stepperSx?: SxProps<Theme>;
}

const Stepper = ({
  activeStep,
  stepperSx,
  setActiveStep,
  setSkipped,
  skipped,
  steps,
}) => {
  const [loading, setLoading] = useState(false);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const isStepOptional = (step: number) => {
    return steps[step].isOptional;
  };

  const stepNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNext = async <T extends (arg: any) => Promise<void>, A>(
    callback: T,
    args: A,
  ) => {
    stepNext();
    setLoading(true);
    await callback(args);
    setLoading(false);
  };

  const stepBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleBack = async <T extends (arg: any) => Promise<void>, A>(
    callback: T,
    args: A,
  ) => {
    stepBack();
    setLoading(true);
    if (callback) {
      await callback(args);
    }
    setLoading(false);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped((prevSkipped: number[]) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleFinish = async <T extends (arg: any) => Promise<void>, A>(
    callback: T,
    args: A,
  ) => {
    setLoading(true);
    await callback(args);
    setLoading(false);
  };

  return (
    <>
      <MUIStepper sx={stepperSx} activeStep={activeStep}>
        {steps.map(({ label, isOptional }, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isOptional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </MUIStepper>
      {loading ? (
        <Box sx={{ padding: "50px" }}>
          <div>Loading!</div>
        </Box>
      ) : (
        <>
          {steps[activeStep].component}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {!steps[activeStep].disableBack && (
              <Button
                size="medium"
                variant="secondary"
                disabled={activeStep === 0}
                onClick={async () =>
                  await handleBack(
                    steps[activeStep].backCallback,
                    steps[activeStep].backCallbackArgs,
                  )
                }
              >
                Voltar
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button size="medium" variant="secondary" onClick={handleSkip}>
                Pular
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                variant="primary"
                size="medium"
                disabled={steps[activeStep].disableNext ?? false}
                onClick={async () =>
                  await handleFinish(
                    steps[activeStep].callback,
                    steps[activeStep].callbackArgs,
                  )
                }
              >
                {steps[activeStep].finishButtonLabel || "Finalizar"}
              </Button>
            ) : (
              <Button
                variant="primary"
                size="medium"
                disabled={steps[activeStep].disableNext ?? false}
                onClick={async () =>
                  await handleNext(
                    steps[activeStep].callback,
                    steps[activeStep].callbackArgs,
                  )
                }
              >
                {steps[activeStep].nextButtonLabel || "Próximo"}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export const useStepper = ({ steps, stepperSx }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const goToStep = (step: number) => {
    setActiveStep(step);
  };

  return {
    Stepper: (
      <Stepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setSkipped={setSkipped}
        skipped={skipped}
        steps={steps}
        stepperSx={stepperSx}
      />
    ),
    goToStep,
  };
};
