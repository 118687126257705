import gql from "graphql-tag";

export const GET_CARD_DELIVERIES = gql`
  query GetCardDeliveries(
    $limit: Int
    $skip: Int
    $externalCardOrderId: String
  ) {
    getCardDeliveries(
      limit: $limit
      skip: $skip
      externalCardOrderId: $externalCardOrderId
    ) {
      cardDeliveries {
        recipient {
          name
        }
        status
        cardAmount
        deliveryAddress {
          address
          addressNumber
          city
          complement
          neighborhood
          referencePoint
          state
          zipCode
        }
        trackingCode
        externalCardDeliveryId
        logisticsPartner
        deliveryInconsistency {
          cpf
        }
      }
      totalCardDeliveries
    }
  }
`;

export type CardDelivery = {
  recipient: CardDeliveryRecipient;
  status: string;
  deliveryAddress: DeliveryAddress;
  trackingCode: string;
  cardAmount: number;
  externalCardDeliveryId: string;
  logisticsPartner?: LogisticsPartnerEnum;
  deliveryInconsistency?: {
    cpf?: boolean;
  };
};

export type DeliveryAddress = {
  address: string;
  addressNumber: string;
  city: string;
  complement: string;
  neighborhood: string;
  referencePoint: string;
  state: string;
  zipCode: string;
};

type CardDeliveryRecipient = {
  name: string;
  email: string;
  phone: string;
};

export type CardDeliveryData = {
  getCardDeliveries: {
    cardDeliveries: CardDelivery[];
    totalCardDeliveries: number;
  };
};

export enum LogisticsPartnerEnum {
  mobi = "mobi",
  tex = "tex",
}
