import { useLazyQuery } from "@apollo/client";
import {
  DataGrid,
  IFetchProps,
  Icons,
  MenuOption,
  MenuOptionProps,
  Skeleton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useContext, useEffect, useState } from "react";
import {
  CardOrder,
  CardOrderData,
  GET_CARD_ORDERS,
} from "../../../../api/queries/get-card-orders";
import {
  GridPagination,
  RefetchProps,
} from "../../../../components/GridPagination/GridPagination";
import dispatchToast from "../../../../utils/dispatchToast";
import { pageSizeOptions, TableUpdate } from "../common";
import { MenuActions } from "../components/MenuActions";
import { FilterField, TableFilters } from "../components/TableFilters";
import { SearchQueryField, TableSearch } from "../components/TableSearch";
import { TableContainer } from "../styles";
import { CardOrderDetailsModal } from "./CardOrderDetailsModal";
import { CardsPageContext } from "../../Context/CardsContext";
import styled from "styled-components";

export const CardOrdersTable = () => {
  const {
    groupCardModel,
    setFetchConfigStateForCardOrderTable,
    fetchConfigStateForCardOrderTable,
  } = useContext(CardsPageContext);
  const INITIAL_PAGE_SIZE = 10;

  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedCardOrder, setSelectedCardOrder] = useState({
    externalCardOrderId: "",
  });
  const [isOrderDetailsModalVisible, setIsOrderDetailsModalVisible] =
    useState(false);

  const [getCardOrders, { data, loading: loadingGetCardOrders }] = useLazyQuery(
    GET_CARD_ORDERS,
    {
      variables: fetchConfigStateForCardOrderTable,
      fetchPolicy: "network-only",
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os Pedidos de cartões.",
        });
      },
    },
  );

  const buildAlert = ({
    externalCardOrderId,
    status,
    cardDeliveryInconsistency,
  }: CardOrder) => {
    if (status === "Finalizado") {
      return (
        <Icons
          name="IconCircleCheck"
          fill="transparent"
          color="green"
          size={40}
        />
      );
    }
    if (cardDeliveryInconsistency) {
      return (
        <Tooltip title='Uma das remessas deste pedido está inconsistente. Cheque os detalhes dela em "Ações" ou clique aqui.'>
          <StyledDiv>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={40}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedCardOrder({ externalCardOrderId });
                setIsOrderDetailsModalVisible(true);
              }}
            />
          </StyledDiv>
        </Tooltip>
      );
    }
    return (
      <Icons
        name="IconCircleCheck"
        fill="transparent"
        color="green"
        size={40}
      />
    );
  };

  const builtTableData = (data: CardOrderData) => {
    if (data?.getCompanyCardOrders?.cardOrders.length > 0) {
      const {
        getCompanyCardOrders: { cardOrders },
      } = data;
      return cardOrders.map((cardOrder: CardOrder) => {
        return {
          ...cardOrder,
          createdAt: new Date(cardOrder.createdAt).toLocaleDateString("pt-br"),
          actions: renderActions({
            externalCardOrderId: cardOrder.externalCardOrderId,
          }),
          cardDeliveryInconsistency: buildAlert(cardOrder),
        };
      });
    }
    return [];
  };

  const renderActions = ({ externalCardOrderId }) => {
    const list: MenuOptionProps[] = [
      {
        onClick: () => {
          setSelectedCardOrder({ externalCardOrderId });
          setIsOrderDetailsModalVisible(true);
        },
        label: "Visualizar Detalhes",
        icon: "IconDotsVertical",
      },
    ];

    return <MenuActions menuOptions={list} />;
  };

  useEffect(() => {
    setFetchConfigStateForCardOrderTable({
      providers: groupCardModel?.cardProviders,
      searchQuery: {},
      filter: {},
      limit: INITIAL_PAGE_SIZE,
      skip: 0,
      sortBy: {
        createdAt: "desc",
      },
    });
  }, []);

  useEffect(() => {
    getCardOrders({
      variables: fetchConfigStateForCardOrderTable,
    });
  }, [fetchConfigStateForCardOrderTable]);

  const handleTableUpdate = (tableUpdate: TableUpdate) => {
    setSelectedPage(1);
    setFetchConfigStateForCardOrderTable((previousState) => ({
      ...previousState,
      limit: pageSize,
      skip: 0,
      ...(tableUpdate.type === "filter" && { filter: tableUpdate.update }),
      ...(tableUpdate.type === "search" && { searchQuery: tableUpdate.update }),
      ...(tableUpdate.type === "sorting" && {
        sortBy: buildSortByFilter(tableUpdate.update.sortBy),
      }),
    }));
  };

  const buildSortByFilter = (sortBy: IFetchProps["sortBy"]) => {
    const [sortOption] = sortBy;
    return {
      createdAt: sortOption.desc ? "desc" : "asc",
    };
  };

  const refetchWithPagination = ({ pageIndex, pageSize }: RefetchProps) => {
    const limit = pageSize;
    const skip = (pageIndex - 1) * pageSize;

    setPageSize(pageSize);
    setFetchConfigStateForCardOrderTable((previousState) => ({
      ...previousState,
      limit,
      skip,
    }));
  };

  const onPageClick = (page: number) => {
    setSelectedPage(page);
    refetchWithPagination({ pageIndex: page, pageSize });
  };

  const onRowsPerPageClick = (itemsPerPage: number) => {
    setSelectedPage(1);
    setPageSize(itemsPerPage);
    refetchWithPagination({ pageIndex: 1, pageSize: itemsPerPage });
  };

  return (
    <>
      <TableContainer>
        <TableSearch
          fields={searchFields}
          onSearch={(search) =>
            handleTableUpdate({ type: "search", update: search })
          }
        />
        <TableFilters
          fields={tableFilters}
          onFilterUpdate={(filter) =>
            handleTableUpdate({ type: "filter", update: filter })
          }
        />
        {loadingGetCardOrders ? (
          <Skeleton
            variant="rectangular"
            style={{
              marginBottom: "40px",
              borderRadius: "20px",
              maxHeight: "828px",
              display: "flex",
              flexGrow: 1,
            }}
          />
        ) : (
          <DataGrid
            columns={columns}
            data={builtTableData(data)}
            pageCount={data?.getCompanyCardOrders?.totalCardOrders / pageSize}
            hasPagination={true}
            fetchData={(input) =>
              handleTableUpdate({ type: "sorting", update: input })
            }
            customPagination={(paginationInput) => (
              <GridPagination
                rowsPerPage={pageSize}
                selectedPage={selectedPage}
                onPageClick={onPageClick}
                rowsPerPageOptions={pageSizeOptions}
                onRowsPerPageClick={onRowsPerPageClick}
                isLoading={loadingGetCardOrders}
                totalItems={data?.getCompanyCardOrders?.totalCardOrders}
                setPageSize={paginationInput.setPageSize}
              />
            )}
          />
        )}
      </TableContainer>
      <CardOrderDetailsModal
        open={isOrderDetailsModalVisible}
        // open={true}
        externalCardOrderId={selectedCardOrder?.externalCardOrderId}
        onClose={() => {
          setIsOrderDetailsModalVisible(false);
          getCardOrders();
        }}
      />
    </>
  );
};

const StyledDiv = styled.div``;

const columns = [
  {
    Header: "Código do pedido",
    accessor: "externalCardOrderId",
    disableSortBy: true,
  },
  {
    Header: "Alertas",
    accessor: "cardDeliveryInconsistency",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "Quantidade",
    accessor: "cardAmount",
    disableSortBy: true,
  },
  {
    Header: "Data do pedido",
    accessor: "createdAt",
  },
  {
    Header: "Ações",
    accessor: "actions",
    disableSortBy: true,
  },
];

const tableFilters: FilterField[] = [
  {
    id: "status",
    checked: false,
    label: "Status",
    options: [
      {
        label: "Processando",
        value: "processing",
      },
      {
        label: "Aprovado",
        value: "approved",
      },
      {
        label: "Negado",
        value: "denied",
      },
      {
        label: "Em fluxo logístico",
        value: "inLogisticsFlow",
      },
      {
        label: "Finalizado",
        value: "finished",
      },
    ],
  },
];

const searchFields: SearchQueryField[] = [
  {
    id: "externalCardOrderId",
    label: "Buscar por Número do pedido",
  },
];
