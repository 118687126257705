import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@mui/material";
import styled from "styled-components";

export const SheetCardOrderValidationView = () => {
  return (
    <SuccessBox id="concluded-card-order-view">
      <ShapeIcon
        variant="default"
        name="IconMoodHappy"
        size={56}
        stroke="default"
      />
      <SuccessTitle>Pedido feito com sucesso!</SuccessTitle>
      <SuccessText>
        Fique de olho na aba <b>pedidos</b> aqui na área de <b>Cartões</b> para
        acompanhar as atualizações do seu pedido.
      </SuccessText>
    </SuccessBox>
  );
};

export const SuccessTitle = styled(Typography)`
  font-family: "Cabinet Grotesk";
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #53464f;
`;

export const SuccessText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #83727d;
`;

export const SuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 70vh;
  justify-content: center;
`;
